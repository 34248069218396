<template>
  <!--歌曲列表-->
  <div class="musicList">
    <template v-if="list.length > 0">
      <div class="list-item list-header">
        <span class="list-name">歌曲</span>
        <span class="list-artist">歌手</span>
        <span v-if="!isDuration && !isDelete" class="list-album">专辑</span>
        <span class="list-time" :style="isDelete == true ? 'margin-right: 60px;':''">时长</span>
      </div>
      <div ref="listContent" class="list-content" @scroll="listScroll($event)">
        <div
          v-for="(item, index) in list"
          :key="item.id"
          class="list-item"
          :class="{ on: playing && currentMusic.id === item.id }"
        >
          <span class="list-num" v-text="index + 1"></span>
          <div class="list-name">
            <span>{{ item.name }}</span>
            <div class="list-menu">
              <music-icon
                class="hover music-icon icon-play"
                title="播放/暂停"
                :type="getPlayIconType(item)"
                :size="40"
                @click.stop="selectItem(item, index)"
              />
              <music-icon
                v-if="!isExistLike(item)"
                class="hover music-icon icon-like"
                title="添加收藏"
                type="ic_favorite_border"
                :size="40"
                @click.stop="likeItem(item, index)"
              />
              <music-icon
                v-if="isExistLike(item)"
                class="hover music-icon icon-like"
                title="取消收藏"
                type="ic_favorite"
                :size="40"
                @click.stop="unlikeItem(item, index)"
              />
              <music-icon
                class="hover music-icon icon-mv"
                title="MV"
                type="bofangMV"
                :size="40"
                @click.stop="playItem(item, index)"
              />
            </div>
          </div>
          <span class="list-artist">{{ item.singer }}</span>
          <span v-if="!isDuration && !isDelete" class="list-album">{{ item.album }}</span>
          <span class="list-time">
            {{ item.duration % 3600 | format }}
          </span>
          <span class="list-time" v-if="isDelete">
            <music-icon
              class="hover music-icon list-menu-icon-del"
              type="delete-mini"
              :size="40"
              @click.stop="deleteItem(index)"
            />
          </span>
        </div>
        <slot name="listBtn"></slot>
      </div>
    </template>
    <empty v-else title="暂未发现歌曲" />
    <music-loading v-model="isLoading" />
  </div>
</template>

<script>
import {getCheckMusic} from 'api'
import { getMusicDetail, getMovieUrl } from 'api'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { format } from '@/utils/util'
import Empty from 'components/music-common/empty/empty'
import MusicLoading from 'components/music-common/loading/music-loading'

const LIST_TYPE_ALBUM = 'album'
const LIST_TYPE_DURATION = 'duration'
const LIST_TYPE_PULLUP = 'pullup'
const LIST_TYPE_DELETE = 'delable'

// 触发滚动加载的阈值
const THRESHOLD = 100

export default {
  name: 'MusicList',
  components: {
    Empty,
    MusicLoading
  },
  filters: {
    format
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    listType: {
      type: String,
      default: LIST_TYPE_ALBUM
    }
  },
  data() {
    return {
      lockUp: true,
      isLoading: false
    }
  },
  computed: {
    isDelete(){
      return this.listType === LIST_TYPE_DELETE
    },
    isDuration() {
      return this.listType === LIST_TYPE_DURATION
    },
    ...mapGetters(['playing', 'currentMusic', 'userLikeList'])
  },
  watch: {
    list(newList, oldList) {
      if (this.listType !== LIST_TYPE_PULLUP) {
        return
      }
      if (newList.length !== oldList.length) {
        this.lockUp = false
      } else if (
        newList[newList.length - 1].id !== oldList[oldList.length - 1].id
      ) {
        this.lockUp = false
      }
    }
  },
  activated() {
    this.scrollTop &&
      this.$refs.listContent &&
      (this.$refs.listContent.scrollTop = this.scrollTop)
  },
  methods: {
    // 滚动事件
    listScroll(e) {
      const scrollTop = e.target.scrollTop
      this.scrollTop = scrollTop
      if (this.listType !== LIST_TYPE_PULLUP || this.lockUp) {
        return
      }
      const { scrollHeight, offsetHeight } = e.target
      if (scrollTop + offsetHeight >= scrollHeight - THRESHOLD) {
        this.lockUp = true
        this.$emit('pullUp')
      }
    },
    // 回到顶部
    scrollTo() {
      this.$refs.listContent.scrollTop = 0
    },
    // 播放暂停事件
    selectItem(item, index, e) {
      if (e && /list-menu-icon-del/.test(e.target.className)) {
        return
      }
      if (this.currentMusic.id && item.id === this.currentMusic.id) {
        this.setPlaying(!this.playing)
        return
      }

      this.$emit('select', item, index)
    },
    // 添加收藏事件
    likeItem(item, index){
      this.setUserLike(item)
      this.$Toast('已添加')
    },
    // 取消收藏事件
    unlikeItem(item, index){
      let list = [...this.userLikeList]
      list.splice(index, 1)
      this.removeUserLike(list)
      this.$Toast('已取消')
    },
    // 是否已收藏
    isExistLike(music){
      const index = this.userLikeList.findIndex(item => {
        return item.id === music.id
      })
      return index >= 0
    },
    // 播放视频事件
    playItem(item, index) {
      this.isLoading = true;

      getMusicDetail(item.id).then(res => {
        this.isLoading = false;

        let mvid = res.songs[0].mv;
        if(mvid == 0){
          this.$Toast('当前歌曲暂无MV');
        }else{
          getMovieUrl(mvid).then(res => {
            if(this.playing){
              this.setPlaying(!this.playing);
            }

            let payload = {
              "url": res.data.url,
              "flag": true
            }
            this.setVideoState(payload)
          }).catch(err => {
            console.log(err);
          })
        }
      }).catch(err => {
        console.log(err);
        this.isLoading = false;
      })
    },
    // 获取播放状态
    getPlayIconType({ id: itemId }) {
      const {
        playing,
        currentMusic: { id }
      } = this
      return playing && id === itemId ? 'bofangzanting' : 'play-mini'
    },
    // 删除事件
    deleteItem(index) {
      this.$emit('del', index)
    },
    ...mapMutations({
      setPlaying: 'SET_PLAYING',
      setVideoState: 'SET_VIDEOSTATE'
    }),
    ...mapActions(['setUserLike', 'removeUserLike'])
  }
}
</script>

<style lang="less" scoped>
.list-header {
  border-bottom: 1px solid @list_head_line_color;
  color: @text_color_active;
  font-weight: bold;

  .list-name {
    padding-left: 40px;
    user-select: none;
  }
}

.list-content {
  width: 100%;
  height: calc(~'100% - 60px');
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.list-no {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: @text_color;
}

.list-item {
  display: flex;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid @list_item_line_color;
  line-height: 50px;
  overflow: hidden;

  &.list-item-no {
    justify-content: center;
    align-items: center;
  }

  &.on {
    color: #fff;

    .list-num {
      font-size: 0;
      background: url('~assets/img/wave.gif') no-repeat center center;
    }
  }

  &:hover {
    .list-name {
      padding-right: 80px;

      .list-menu {
        display: block;
      }
    }
  }

  &:not([class*='list-header']):hover {
    .list-name {
      padding-right: 80px;

      .list-menu {
        display: block;
      }
    }

    .list-time {
      .list-menu-icon-del {
        display: block;
      }
    }
  }

  .list-num {
    display: block;
    width: 30px;
    margin-right: 10px;
    text-align: center;
  }

  .list-name {
    position: relative;
    flex: 1;
    box-sizing: border-box;

    & > span {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    small {
      margin-left: 5px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.5);
    }

    /*hover菜单*/

    .list-menu {
      display: none;
      position: absolute;
      top: 50%;
      right: 10px;
      height: 40px;
      font-size: 0;
      transform: translateY(-50%);
    }
    .list-menu .music-icon{
      margin-right: 5px;
    }
  }

  .list-artist,
  .list-album {
    display: block;
    width: 300px;
    .no-wrap();
    @media (max-width: 1440px) {
      width: 200px;
    }
    @media (max-width: 1200px) {
      width: 150px;
    }
  }

  .list-time {
    display: block;
    width: 60px;
    position: relative;

    .list-menu-icon-del {
      display: none;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
}

@media (max-width: 960px) {
  .list-item .list-name {
    padding-right: 70px;
  }
}

@media (max-width: 768px) {
  .list-item {
    .list-name .list-menu {
      display: block;
    }

    .list-artist,
    .list-album {
      width: 20%;
    }
  }
}

@media (max-width: 640px) {
  .list-item {
    .list-artist {
      width: 80px;
    }

    .icon-mv{
      display: none;
    }

    .list-album,
    .list-time {
      display: none;
    }
  }
}
</style>
